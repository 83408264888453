<template>
  <div class="siteplan">
    <iframe src="./siteplan.html" frameborder="0"></iframe>
    <!-- <img src="@/assets/14.png" alt=""> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style lang="less">
.siteplan{
  width: 100%;
  height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  iframe{
    width: 100%;
    height: 100%;
  }
  img{
    width: 100%;
    max-height: initial;
  }
}
</style>