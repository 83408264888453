<template>
  <div class="home">
    <div class="tab">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="iphone-css" @click="IphoneTabType=!IphoneTabType">
      三
    </div>
      <div class="tab-list" v-if="IphoneTabType">
        <div class="tab-btn-list" :class="{ setpage1: $route.path == name.path }"
        v-for="(name, i) in tablist" :key="i"
        @click="scrollToElement(name.pagename),IphoneTabType=false" >
          {{name.name}}
        </div>
      <!-- <div class="close-tab" @click="IphoneTabType=false">X</div> -->
        
      </div>
    <div class="pages">
      <div
        class="tab-page"
        
        @click="scrollToElement(itme.pagename)"
        v-for="(itme, i) in tablist"
        :key="i"
      >
        <span class="tab-text" :class="{ setpage: $route.path == itme.path }">{{ itme.name }}</span>
        <span class="tab-interval" v-if="i < tablist.length - 1"> </span>
      </div>
    </div>
    </div>
    <div id="info" class="home-pages">
      <info/>
    </div>
    <div id="maps" class="home-pages">
      <maps/>
    </div>
    <div id="siteplan" class="home-pages"> 
      <siteplan/>
    </div>
    <div ref="observer" class="box home-pages box-enter-active" v-if="showAnimation">
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
      asddddddddddddddddd
    </div>
    <div id="floorplan"  class="home-pages"> 
      <floorplan/>
    </div>
    <div id="gallery" class="home-pages"> 
      <gallery/>
    </div>
    <div id="about" class="home-pages"> 
      <about/>
    </div>
  </div>
</template>

<script>
import info from "./info.vue"
import maps from "./location.vue"
import siteplan from "./siteplan.vue"
import floorplan from "./floorplan.vue"
import gallery from "./gallery.vue"
import about from "./about.vue"

export default {
  components:{
    info,
    maps,
    siteplan,
    floorplan,
    gallery,
    about
  },
  data() {
    return {
      IphoneTabType:false,
      showAnimation: false,
      observer: null,
      tablist: [
        {path: "/",pagename:"info",name: "Project Info",meta: {showtype: true,showTab: true,},},
        {path: "/location",pagename:"maps",name: "Location Map",meta: {showtype: true,showTab: true,},},
        {path: "/siteplan",pagename:"siteplan",name: "Site Plan",meta: {showtype: true,showTab: true,},},
        {path: "/floorplan",pagename:"floorplan",name: "Floor Plan",meta: {showtype: true,showTab: true,},},
        {path: "/gallery",pagename:"gallery",name: "Gallery",meta: {showtype: true,showTab: true,},},
        {path: "/about",pagename:"about",name: "About Developer",meta: {showtype: true,showTab: true,},},
        
      ],
    }
  },
  mounted() {
    // 在组件挂载后初始化 Intersection Observer
    this.initIntersectionObserver();
  },
  methods:{
    scrollToElement(elementId) {
    const element = document.getElementById(elementId);

      if (element) {
        // 使用scrollIntoView()方法滚动到指定元素
        element.scrollIntoView({
          behavior: 'smooth', // 使用平滑滚动效果
          block: 'start' // 将目标元素的顶部对齐到可视区域的顶部
        });
      }
    },
    goPage(pagePath) {
      this.tabindex = pagePath;
      this.$router.push(pagePath).catch((err) => {});
    },
    initIntersectionObserver() {
      // 创建 Intersection Observer 实例
      this.observer = new IntersectionObserver(this.handleIntersection, { threshold: 0.5 });
      // 监听目标元素
      this.observer.observe(this.$refs.observer);
    },
    handleIntersection(entries) {
      console.log(33333333);

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.showAnimation = true;
          // 停止监听目标元素，避免重复触发动画
          this.observer.unobserve(this.$refs.observer);
        }
      });
    }
  },
  beforeDestroy() {
    // 在组件销毁时停止 Intersection Observer 的监听
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>

<style lang="less">
  .home{
    width: 100%;
    height: 100%;
    background: #f0ebe4;
    overflow: auto;
    .home-pages{
      width: 80%;
      margin: auto;
      position: relative;
      padding: 25px 0;
      
      // animation-name:fadeIn ; /*fadeInLeft为要使用的动画效果名，在这里不需要加animate前缀*/
      // animation-duration: 1s; /*这里设定完成该动画的时间*/
    }
    .tab {
      width: 100%;
      height: 7%;
      background-color: #856648;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 121;
      .logo {
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .iphone-css{
        display: none;
        color: white;
        padding: 10px;
        font-size: 1.7rem;
      }

      .pages {
        width: 75%;
        display: flex;
        flex-direction: row;
        .tab-page {
          line-height: 100%;
          font-size: 1.2rem;
          text-align: center;
          margin: auto 0;
          color: rgb(197, 197, 197);
          cursor: pointer;
          .tab-interval {
            padding: 0 1.5rem;
          }

        }
      }
      .setpage1{
        color: white !important;
        font-weight: 500;
        background: #ffffff3d !important;
      }
      .setpage {
        color: white !important;
        padding-bottom: 7px;
        font-weight: 500;
        border-bottom: 3px solid #f5f5f5;
      }

      
      .tab-list{
        position: absolute;
        top: 7%;
        right: 0px;
        width: 40%;
        display: flex;
        flex-flow: column;
        align-items: baseline;
        text-align: start;
        font-weight: bold;
        background: #856648;
        animation-name:slideInRight; /*fadeInLeft为要使用的动画效果名，在这里不需要加animate前缀*/
        animation-duration: 0.3s; /*这里设定完成该动画的时间*/
        z-index: 120;
      .tab-btn-list{
        padding: 10px;
        color: white;
        width: 100%;
      }
      .tab-btn-list:hover{
        padding: 8px;
        color: black;
        background: white;
      }

      }

    }
    .imfo{

    }
    #floorplan{
      height: 95vh;
    }
    #maps{
      height: 75vh;
    }

  }
/* 定义目标元素的样式 */
.box {
  width: 100px;
  height: 100px;
  background-color: red;
}

/* 定义目标元素进入视口时的过渡动画 */
.box-enter-active {
  animation-name:backInDown; /*fadeInLeft为要使用的动画效果名，在这里不需要加animate前缀*/
  animation-duration: 5s; /*这里设定完成该动画的时间*/
}

  @media screen and (max-width: 800px){
  .iphone-css{
    display: flex !important;
  }
  .pages {
    display: none !important;
  }
  #floorplan{
      height: 45vh !important;
    }
  #maps{
    height: 45vh !important;
  }
}
</style>