<template>
  <div class="gallery">

    <swiper class="swiper swipertopb" :options="swiperOption" ref="swiperOption" >
        <swiper-slide  v-for="item,i in nowdata" :key="i">
        <img v-if="item.type == 'img'" ondragstart="return false;" :src="item.url" alt="">
        <!-- <div v-if="item.type == 'img'" :style="`background-image: url(${item.url});background-size: cover;`"></div> -->
        <video  v-else style="width: 100%;height: 100%;" :src="item.url" autoplay controls muted loop></video>
        </swiper-slide>

    </swiper>
    <div class="swiper-button-prev" style="left: 10%;top: 50%;"><img src="@/assets/btn/left.png" alt=""></div>
    <div class="swiper-button-next" style="right:10%;top: 50%;"><img src="@/assets/btn/right.png" alt=""></div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true,//修改swiper的父元素时，自动初始化swiper
        slidesPerView: 1,
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
        },

        },
        nowdata:[
          {name:"A",type:'video',url:require("@/assets/gallery/015-165JLB-01.mp4")},
          {name:"A",type:'img',url:require("@/assets/gallery/015-165JLB-v1-3.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/015-165JLB-v2-7.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/015-165JLB-v3-8.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/015-165JLB-v4-4.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/015-165JLB-v5-4.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/015-165JLB-v6-6.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/v8.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/v9.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/v10.png")},
          {name:"A",type:'img',url:require("@/assets/gallery/v11.png")},
        ],
    }
  },
}
</script>

<style lang="less">
  .gallery{
    width: 100%;
    height: 90%;
        
    .swiper{
    width: 100%;
    height: 100%;
    }

    .swiper-slide{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: inherit;
        overflow: auto;
        background-color: black;
        img{
            max-height: 100%;
            max-width: 100%;
            // width: 100%;
        }
        video{
          width: 100%;
          height: 100%;
        }
        .swp-content{
          position: absolute;
          z-index: 999;
          width: 50%;
          height: 50%;
          h4,p{
          color: white;
          margin: auto;
        }

        }

    }
    .swiper-button-prev,.swiper-button-next{
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      img{
        max-height: 5rem;
        max-width: 5rem;
      }
    }
  }
  @media screen and (max-width: 800px){
    .swiper-slide{
      justify-content: center !important;
    }
    .swiper-button-prev,.swiper-button-next{

      img{
        max-height: 45px;
        max-width: 45px;
      }
    }

  }

</style>