<template>
  <div class="about">
    <img src="@/assets/51a.jpg" alt="">
    <img src="@/assets/51b.jpg" alt="">
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style lang="less">
  .about{
    width: 100%;
    height: 90%;
    overflow: auto;
    background: black;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img{
      width: 50%;
      height: auto;
      max-height: initial;
    }
  }

  @media screen and (max-width: 800px){
    .about img{
      width: 100% !important;
    }
  }
</style>