<template>
  <div class="location">
    <iframe v-if="maptype" src="./location.html" frameborder="0"></iframe>
    <iframe v-else src="https://www.mixgovr.com/AUDAX/165jlb-drone/" frameborder="0"></iframe>
    <div class="maptypebtn" @click="maptype=!maptype">
      <img v-if="maptype"  src="@/assets/btn/720.png" alt="">
      <img  v-else src="@/assets/btn/switch.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maptype:true, 
    }
  },
  methods:{

  }
}
</script>

<style lang="less">
  .location{
    width: 100%;
    height: 90%;
    overflow: hidden;
    iframe{
      width: 100%;
      height: 100%;
    }
    .maptypebtn{
      position: fixed;
      bottom: 5%;
      right: 5%;
      width: 45px;
      height: 45px;
      padding: 10px;
      border: 10px solid #856648; /* 2像素宽的黑色实线边框 */
      border-radius:50%; /* 添加10像素的圆角效果 */
      background-color: white;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
</style>