<template>
  <div class="brochure">

    <swiper class="swiper swipertopb" :options="swiperOption" ref="swiperOption" >
        <swiper-slide  v-for="item,i in nowdata" :key="i">
        <img ondragstart="return false;"  :src="item.url" alt="">
        </swiper-slide>

    </swiper>
    <div class="swiper-button-prev" style="left: 10%;top: 50%;"><img src="@/assets/btn/left.png" alt=""></div>
    <div class="swiper-button-next" style="right:10%;top: 50%;"><img src="@/assets/btn/right.png" alt=""></div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true,//修改swiper的父元素时，自动初始化swiper
        slidesPerView: 1,
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
        },

        },
        nowdata:[
        ],
    }
  },
  methods:{
    imglist(){
      for (let index = 0; index < 53; index++) {
        this.nowdata.push({name:"01",url:require(`@/assets/E/${this.formatZero(index, 2)}.png`)})
      }
    },
    formatZero(num, length) {
      // 获取动态文件名称
      //num:要处理的参数
      // length: 要返回的总长度
      return (Array(length).join(0) + num).slice(-length);
    },
  },
  mounted(){
    this.imglist()
  }
}
</script>

<style lang="less">
  .brochure{
    width: 100%;
    height: 90%;
    background: black;
    .swiper{
    width: 100%;
    height: 100%;
    }

    .swiper-slide{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: auto;
        img{
            max-height: 100%;
            max-width: 100%;
            // width: 100%;
        }
        .swp-content{
          position: absolute;
          z-index: 999;
          width: 50%;
          height: 50%;
          h4,p{
          color: white;
          margin: auto;
        }

        }

    }
    .swiper-button-prev,.swiper-button-next{
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      img{
        max-height: 5rem;
        max-width: 5rem;
      }
    }
  }
  @media screen and (max-width: 800px){
    .swiper-button-prev,.swiper-button-next{

      img{
        max-height: 45px;
        max-width: 45px;
      }
    }

  }

</style>