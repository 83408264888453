<template>
  <div class="floorplan">
    <!-- <img src="@/assets/26.png" alt=""> -->
    <!-- <span style="font-weight: bold;font-size: 36px;margin: auto;">To Be Updated</span> -->
    <iframe src="./floorplan.html" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style lang="less">
.floorplan{
  width: 100%;
  height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  iframe{
    width: 100%;
    height: 100%;
  }
  img{
    width: 100%;
    max-height: initial;
  }
}

</style>